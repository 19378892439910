module services {
    'use strict';
    export module purchase {
        export class PurchaseOrderSKUService implements interfaces.purchase.IPurchaseOrderSKUService {
            static $inject = ['$resource', 'ENV'];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {

            }

            getPurchaseOrderSKUListByPOId(): ng.resource.IResourceClass<interfaces.purchase.IPurchaseOrderDetailSKUDisplay> {
                return this.$resource<interfaces.purchase.IPurchaseOrderDetailSKUDisplay>(this.ENV.DSP_URL + "PurchaseOrderSKU/GetListByPurchaseOrderId", {
                        ordId: '@ordId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            params: {
                                ordId: 0
                            }
                        }
                    });
            }

            getDetail(): ng.resource.IResourceClass<interfaces.purchase.IPurchaseOrderSKU> {
                return this.$resource<interfaces.purchase.IPurchaseOrderSKU>(this.ENV.DSP_URL + "PurchaseOrderSKU/GetDetail", {
                        posId: '@posId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            params: {
                                posId: 0
                            },
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "PurchaseOrderSKU/Save");
            }

            saveInline(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "PurchaseOrderSKU/SaveInline");
            }

            delete(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "PurchaseOrderSKU/Delete", {
                    purchaseOrderDetailId: '@purchaseOrderDetailId'
                });
            }

            deleteNationalMeasureCode(pnmId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "PurchaseOrderSKU/DeleteNationalMeasureCode", {
                    pnmId: pnmId
                });
            }

            getSKUDefaults(): ng.resource.IResourceClass<interfaces.master.ISKU> {

                return this.$resource<interfaces.master.ISKU>(this.ENV.DSP_URL + "PurchaseOrderSKU/GetSKUDefaults", {
                    skuId: "@skuId",
                    posId: '@posId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
        }
    }
    angular.module("app").service("purchaseOrderSKUService", services.purchase.PurchaseOrderSKUService);
}